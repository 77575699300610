<template>
	<div>
		<v-toolbar class="mb-auto" rounded="t-xl">
			<v-btn fab plain :to="{ name: 'User', params: { id: attendance.user.id } }">
				<v-avatar class="mr-2">
					<v-img alt="Avatar" :src="attendance.user.imgURL" />
				</v-avatar>
			</v-btn>
			<v-toolbar-title>
				{{ attendance.user.fullname }}
				<br />
				<router-link class="text-subtitle-2 text-uppercase text-decoration-none red--text" :to="{ name: 'Event', params: { id: event.id } }">
					{{ event.title }}
				</router-link>
			</v-toolbar-title>
			<v-spacer />
		</v-toolbar>
		<v-card class="d-flex flex-column justify-space-between" rounded="b-xl" :style="{ height: chatHeight }">
			<div class="flex-grow-1 d-flex flex-column scrollable my-2 py-3" ref="messagesContainer">
				<p class="text-center">{{ $t('chats.noMessages') }}</p>
			</div>
			<v-textarea
				v-model="content"
				filled
				outlined
				clear-icon="mdi-close-circle"
				clearable
				:rows="1"
				auto-grow
				label="Message"
				:loading="loading"
				:append-icon="content ? 'mdi-send' : ''"
				@click:append="callNewChat()"
				@click:clear="content = ''"
				class="flex-grow-0 flex-shrink-1 pt-1 overflow-auto"
				style="max-height: 50%"
			/>
		</v-card>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.messages')} - ${this.attendance.user.fullname}`
		}
	},
	props: {
		attendance: {
			type: Object,
			required: true
		},
		event: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			content: ''
		}
	},
	computed: {
		chatHeight() {
			var x = this.$vuetify.breakpoint.height
			if (x > 2000) return '80vh'
			else if (x < 400) return '10vh'
			else
				return `${-4.25 * Math.pow(10, -11) * Math.pow(x, 4) +
					0.000000265 * Math.pow(x, 3) -
					0.0005776327 * Math.pow(x, 2) +
					0.5488767355 * x -
					117}vh`
		},
		...mapGetters({
			company: 'companies/company'
		})
	},
	methods: {
		callNewChat() {
			this.loading = true
			this.newChatEvent({ attendanceID: this.attendance.id, content: this.content }).then(({ success, chat }) => {
				if (success) {
					this.$router.push({ name: 'MessagesChat', params: { id: chat.id } })
				}
			})
		},
		...mapActions('chats', ['newChatEvent'])
	}
}
</script>

<style scoped>
.scrollable {
	overflow-y: auto;
}
</style>
